<template>
  <div class="admin-emergency-types">
    <div class="page-header">
      <h1 class="page-title">
        <!-- <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin/responders')"
          icon="left"
        ></a-button> -->

        <admin-org-indicator></admin-org-indicator>

        <!-- Admin - Responders - Response Teams -->
        Admin - Response Teams
      </h1>
      <div class="actions">
        <!-- Search -->
        <a-input-search
          size="large"
          placeholder="Search response teams"
          v-model="searchQuery"
        />
        <!-- / Search -->

        <a-button
          @click.prevent="loadResponseTeams"
          :disabled="isLoading"
          class="button-margin-left btn-rounded"
          icon="reload"
          size="large"
          type="default"
          >Refresh</a-button
        >

        <a-button
          @click.prevent="createResponseTeam"
          class="button-margin-left btn-rounded"
          icon="plus"
          size="large"
          type="primary"
          >Create</a-button
        >
      </div>
    </div>
    <!-- List wrapper -->
    <div class="response-teams-list-wrapper">
      <a-alert
        style="margin-bottom: 25px"
        message="Response Teams are the groups of staff notified for particular incident types."
      >
      </a-alert>

      <!-- <div class="incident-type-org-context-switcher-wrapper">
        <playbook-org-context-switcher
          @set-owner-id-filter="setOwnerIdFilter"
          :owner-id-filter="ownerIdFilter"
        ></playbook-org-context-switcher>
      </div> -->

      <!-- Loading -->
      <div class="loader" v-if="isLoading">
        <a-spin></a-spin>
      </div>
      <!-- / Loading -->

      <!-- No response teams -->
      <a-alert
        v-if="!isLoading && responseTeamsToShow.length == 0"
        type="info"
        message="No response teams to show"
      >
      </a-alert>
      <!-- / No incident types -->

      <!-- Loaded -->
      <div
        class="response-teams-list"
        v-if="!isLoading && responseTeamsToShow.length"
      >
        <a-row type="flex" :gutter="20">
          <a-col
            :span="colSpan"
            v-for="responseTeam in responseTeamsToShow"
            :key="responseTeam.id"
          >
            <response-team-list-item
              :response-team="responseTeam"
              :tenant-id="tenantId"
              :org-id="selectedOrganisation.id"
              @selected="() => navigateToResponseTeam(responseTeam)"
            ></response-team-list-item>
          </a-col>
        </a-row>
      </div>
      <!-- / Loaded -->
    </div>
    <!-- / List wrapper -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ResponseTeamListItem from "./ResponseTeams/ResponseTeamListItem.vue";
import skills from "../../../api/skills";
// import PlaybookOrgContextSwitcher from "../../../components/Admin/Playbooks/PlaybookOrgContextSwitcher.vue";
const _ = require("lodash");
export default {
  components: { ResponseTeamListItem },

  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: false,
      responseTeams: [],
      searchQuery: "",
      // ownerIdFilter: null,
    };
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
    // this.ownerIdFilter = this.selectedOrganisation.id;
    this.loadResponseTeams();
  },

  watch: {
    selectedOrganisation() {
      this.loadResponseTeams();
    },
  },

  methods: {
    // setOwnerIdFilter(val) {
    //   this.ownerIdFilter = val;
    // },

    navigateToResponseTeam(responseTeam) {
      this.$router.push("/admin/responders/response-teams/" + responseTeam.id);
    },

    createResponseTeam() {
      this.$router.push("/admin/responders/response-teams/create");
    },

    loadResponseTeams() {
      let vm = this;
      vm.isLoading = true;
      vm.responseTeams = [];
      skills
        .getSkills(this.tenantId, this.selectedOrganisation.id, true)
        .then((r) => {
          vm.responseTeams = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoading = false;
          vm.$message.error("Error loading response teams");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
      tenantId: "tenantId"
    }),

    colSpan() {
      // return this.windowWidth < 1200 ? 24 : 12;
      return 24;
    },

    responseTeamsToShow() {
      let filtered = _.filter(this.responseTeams, (responseTeam) => {
        if (this.searchQuery.trim().length) {
          return responseTeam.displayName
            .toLowerCase()
            .includes(this.searchQuery.trim().toLowerCase());
        } else {
          return true;
        }
      });
      return _.orderBy(filtered, ["rank", "displayName"], ["desc"]);
      // return _.filter(ordered, (incidentType) => {
      //   return incidentType.ownerId == this.ownerIdFilter;
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.response-team-list-item {
  margin-bottom: 15px;
}

.button-margin-left {
  margin-left: 15px;
}

.incident-type-org-context-switcher-wrapper {
  margin-bottom: 25px;
}
</style>